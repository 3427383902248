import { Component, OnInit, Output, EventEmitter } from '@angular/core'; //HostListener pendiente para lo de scroll top
import { servicioPeticiones } from '@servicios/servicioPeticiones';
import { DatosGeneralesService } from '@servicios/datosGenerales.service';
import html2canvas from 'html2canvas';
import 'chartjs-plugin-labels'; // Necesario para poner los labels encima de las gráficas
import { ContenedorDashboard, ContenedorRespuesta, Dataset, DatosGrafica, Estadistica, Grafica, RespuestaDasboard, ValoresGrafica } from '@src/app/interfaces/dashboard';
import { BARCHARTIOPTIONS, BARCHARTIOPTIONSFORMAT, BARCHARTIOPTIONSFORMATSERIES, MEMBERCHARTOPTIONS, MEMBERCHARTOPTIONSFORMAT } from './dashboard.constants';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  public mensaje: string;
  public jsonGraficas: RespuestaDasboard;//Json que se recibe inicial para mostrar el dashboard
  public estadisticas: Estadistica[];//Json de estadísticas principales que se toma desde el jsonPrincipal
  public jsonContenedores: ContenedorDashboard[];//Json que contiene los contenedores con las gráficas principales del dashboard
  public tablaEstadistica: Estadistica[];//Json que contendrá tel listado de indicadores
  public esDetalle: boolean = false;//Indica si estoy en el formulario detalle o no, ésto ademas cambia la configuración de las gráficas
  public tituloDetalle: string = '';//Título que se mostrará en el formualio de detalle
  public llamado: any;//Json con los datos a enviar al backend
  public loading: boolean = false;
  public fechaActual: string;//Fecha actual
  public fechaDetalle: string;//Fecha en la que se calcula el detalle
  public memberChartOptions: any; //Doughnut and pie
  public memberChartOptionsFormat: any;
  public barChartOptions: any; //Bar
  public barChartOptionsFormat: any; //Bar con formato pesos
  public barChartOptionsFormatSerie: any; //Bar con formato pesos
  public idesta: string = '';
  public idContenedorOculto: string; // Contenedor que se está imprimiendo
  public fechaFiltro: string = '';
  public tiposDashboard: any;
  public tipoDashboard: any = null;
  public rentas: any;
  public marketplaces: any;
  public comercios: any;
  public renta: any = null;
  public marketplace: any = null;
  public comercio: any = null;
  public abrirDialogoFiltros: boolean = false;
  public tipoDash: string;
  public idempr: string;
  public idsesi: number;
  public idrenta: number;
  public empresaFiltro: number;

  @Output() enviarMensaje = new EventEmitter();

  constructor(private _servicioPeticiones: servicioPeticiones, private _servicioDatosGenerales: DatosGeneralesService) { }

  ngOnInit() {
    if (this._servicioDatosGenerales.getIdentity()) {
      this.idempr = this._servicioDatosGenerales.getIdentity().usuario.empresa;
      this.idsesi = this._servicioDatosGenerales.getIdentity().idSesion;
      this.definirConfiguracionGraficas();
      this.llenarFiltrosDashboard();
      // this.tipoDash = this.definirTipoDasboard();
      this.obtenerGraficas();
    }
  }

  definirConfiguracionGraficas() {
    // Doughnut and pie
    this.memberChartOptions = MEMBERCHARTOPTIONS;
    this.memberChartOptionsFormat = MEMBERCHARTOPTIONSFORMAT;
    // Bar and Line
    this.barChartOptions = BARCHARTIOPTIONS;
    this.barChartOptionsFormat = BARCHARTIOPTIONSFORMAT;
    this.barChartOptionsFormatSerie = BARCHARTIOPTIONSFORMATSERIES;
  }

  llenarFiltrosDashboard() {
    let datosUsuario: any = this._servicioDatosGenerales.getIdentity().usuario;
    this.tiposDashboard = [];
    if (datosUsuario.gerencial) {
      this.tiposDashboard.push({ label: 'Gerencial', id: 'G' });
    }
    if (datosUsuario.tecnico) {
      this.tiposDashboard.push({ label: 'Técnico', id: 'T' });
    }
    this.rentas = datosUsuario.modulos ? datosUsuario.lineas : [];
    this.marketplaces = datosUsuario.relacionadasM;
    this.comercios = datosUsuario.relacionadasC;
  }

  cambiarFiltrosDashboard() {
    // this.tipoDash = this.definirTipoDasboard();
    this.obtenerGraficas();
    this.esDetalle = false;
  }

  restaurarComercios() {
    this.comercios = this._servicioDatosGenerales.getIdentity().usuario.relacionadasC;
  }

  //Función que carga las gráficas iniciales del dashboard
  //Función encargada de hacer la petición al api-rest para traer el menú a cargar en la interfaz
  obtenerGraficas() {
    this.loading = true;
    this.fechaActual = this.calcularFechaHora();
    this.tipoDash = this.tipoDashboard ? this.tipoDashboard.id : (this.tiposDashboard && this.tiposDashboard.length > 0) ? this.tiposDashboard[0].id : 'N';
    this.empresaFiltro = this.comercio ? this.comercio.id : (this.marketplace ? this.marketplace.id : null);
    this.idrenta = this.renta ? this.renta.id : null;
    this.llamado = { "accion": "listar_estadisticas", parametros: { "codigoSesion": this._servicioDatosGenerales.getIdentity().codigoSesion, "idempresa": this.empresaFiltro, "tipoDash": "" + this.tipoDash, "renta": this.idrenta } };
    this._servicioPeticiones.enviarObjetoBus(this.llamado)
      .subscribe( //suscribe la peticion http para estar pendiente de la respuesta, funciona como un callback
        response => { //si la peticion fue exitosa ejecuta la funcion response
          let respuesta = response[0].valores;
          if (respuesta) {
            if (respuesta['resultado'] == 0) {//Si No obtiene problemas al traer el menú
              this.jsonGraficas = (respuesta);
              this.estadisticas = this.jsonGraficas['estadistica'];
              this.tablaEstadistica = this.jsonGraficas['tablaEstadistica'];
              this.jsonContenedores = this.jsonGraficas['contenedores'] ? this.construirJsonGraficas(this.jsonGraficas['contenedores']) : null;
              if (!this.jsonContenedores) {
                this.enviarMensaje.emit({ mensaje: "Dashboard no disponible", tipo: 'I' })
              }
            } else {
              this.mensaje = respuesta['mensaje'];
              this.enviarMensaje.emit({ mensaje: this.mensaje, tipo: 'E' })
            }
          } else {
            this.mensaje = 'Ocurrió un error inesperado, por favor contacte al administrador del sistema';
            this.enviarMensaje.emit({ mensaje: this.mensaje, tipo: 'E' })
          }
          this.loading = false;
        },
        error => {
          this.loading = false;
          this.mensaje = 'Ocurrió un error inesperado al intentar cargar el dashboard';
          this.enviarMensaje.emit({ mensaje: this.mensaje, tipo: 'E' })
        }
      )
  }

  /**
   * Función para construir el json con los contenedores de las gráficas del dashboard
   */
  construirJsonGraficas(contenedores: ContenedorRespuesta[]): ContenedorDashboard[] {
    return contenedores.map(contenedor => {
      return {
        ...contenedor,
        graficas: contenedor.graficas.map(grafica => {
          // Si es una tabla, no es necesario reconstruir la información para las graficas
          return grafica.tipoGrafica === 3 ? grafica : this.construirInformacionGraficas(grafica)
        })
      }
    })
  }

  /**
   * Función para construir estructura e información de cada gráfica (Barras, lineas, donas, tortas)
   */
  construirInformacionGraficas(grafica: Grafica): DatosGrafica {
    return {
      ...grafica,
      labels: grafica.datos.titulos[0] || [],
      datasets: this.construirDataset(grafica),
    };
  }

  /**
   * Función que valida cada tipo de gráfica para constriur la estructura necesaria para cada una de estas con su información
   */
  construirDataset(grafica: Grafica): Dataset[] {
    let dataset: Dataset[] = [];
    switch (grafica.tipoGrafica) {
      case 1: // Bar
        dataset = this.construirEstructuraGraficaBarras(grafica.datos, grafica.titulo)
        break;
      case 2: // Pie
        dataset = this.construirEstructuraGraficaPie(grafica.datos)
        break;
      case 4: // Line
        dataset = this.construirEstructuraGraficaLinea(grafica.datos, grafica.titulo)
        break;
      case 6: // doughnut
        dataset = this.construirEstructuraGraficaDona(grafica.datos, grafica.titulo)
        break;
      case 7: // Parallel Bar
        dataset = this.construirEstructuraGraficaBarrasParalelo(grafica.datos)
        break;
      case 8: // Parallel Line
        dataset = this.construirEstructuraGraficaLineasParalelo(grafica.datos)
        break;
      default:
        dataset = [];
        break;
    }
    return dataset;
  }

  construirEstructuraGraficaBarras(datos: ValoresGrafica, titulo: string): Dataset[] {
    return [{
      tipoDataset: "bar",
      label: titulo,
      data: datos.valores[0],
      backgroundColor: datos.colores[0],
      hoverBackgroundColor: datos.colores[0],
      options: this.barChartOptionsFormat
    }]
  }

  construirEstructuraGraficaPie(datos: ValoresGrafica): Dataset[] {
    return [{
      tipoDataset: "pie",
      data: datos.valores[0],
      backgroundColor: datos.colores[0],
      hoverBackgroundColor: datos.colores[0],
      options: this.memberChartOptionsFormat
    }]
  }

  construirEstructuraGraficaLinea(datos: ValoresGrafica, titulo: string): Dataset[] {
    return [{
      tipoDataset: "line",
      label: titulo,
      data: datos.valores[0] || [],
      borderColor: datos.colores[0] ? datos.colores[0][0] : [],
      pointBackgroundColor: datos.colores[0] ? datos.colores[0][0] : [],
      pointBorderColor: datos.colores[0] ? datos.colores[0][0] : [],
      options: this.barChartOptionsFormat
    }]
  }

  construirEstructuraGraficaDona(datos: ValoresGrafica, titulo: string): Dataset[] {
    return [{
      tipoDataset: "doughnut",
      data: datos.valores[0],
      backgroundColor: datos.colores[0],
      hoverBackgroundColor: datos.colores[0],
      options: this.memberChartOptionsFormat
    }]
  }

  construirEstructuraGraficaBarrasParalelo(datos: ValoresGrafica): Dataset[] {
    let datasetList: Dataset[] = [];
    for (let i = 0; i < datos.valores.length; i++) {
      datasetList.push({
        tipoDataset: "bar",
        label: datos.subtitulos[i][0] || '',
        backgroundColor: datos.colores[i][0],
        borderColor: datos.colores[i][0],
        data: datos.valores[i],
        options: this.barChartOptionsFormatSerie
      })
    }
    return datasetList;
  }

  construirEstructuraGraficaLineasParalelo(datos: ValoresGrafica): Dataset[] {
    let datasetList: Dataset[] = [];
    for (let i = 0; i < datos.valores.length; i++) {
      datasetList.push({
        tipoDataset: "line",
        label: datos.subtitulos[i][0] || '',
        borderColor: datos.colores[i][0],
        pointBackgroundColor: datos.colores[i][0],
        pointBorderColor: datos.colores[i][0],
        data: datos.valores[i],
        options: this.barChartOptionsFormatSerie
      })
    }
    return datasetList;
  }

  //Función para cargar el detalle de la gráfica seleccionada
  //Recibe el id de la estadística, el tipo de estadistica (E, estadística ó D, detalle) y el título de la estadístca para ser pntado en el detalle
  generarDetalleGrafica(id: string, tipo: string, titulo: string) {//Se recibe el id de la gráfica
    this.fechaDetalle = this.calcularFechaHora();
    this.loading = true;
    this.llamado = { "accion": "detalle_estadistica", parametros: { "codigoSesion": this._servicioDatosGenerales.getIdentity().codigoSesion, "tipo": tipo, "relacion": id, "idempresa": this.empresaFiltro, "tipoDash": "" + this.tipoDash, "renta": this.idrenta } };
    this._servicioPeticiones.enviarObjetoBus(this.llamado)
      .subscribe( //suscribe la peticion http para estar pendiente de la respuesta, funciona como un callback
        response => { //si la peticion fue exitosa ejecuta la funcion response
          let respuesta = response[0].valores;
          if (respuesta) {
            if (respuesta['resultado'] == 0) {//Si No obtiene problemas al traer el menú
              this.jsonGraficas = (respuesta);
              this.estadisticas = null;
              this.jsonContenedores = this.construirJsonGraficas(this.jsonGraficas['contenedores']);
              this.tablaEstadistica = null;
              this.tituloDetalle = titulo;
              this.esDetalle = true;
            } else {
              this.mensaje = respuesta['mensaje'];
              this.enviarMensaje.emit({ mensaje: this.mensaje, tipo: 'E' })
            }
          } else {
            this.mensaje = 'Ocurrió un error inesperado, por favor contáctese con el administrador del sistema';
            this.enviarMensaje.emit({ mensaje: this.mensaje, tipo: 'E' })
          }
          this.loading = false;
        },
        error => {
          this.loading = false;
          this.mensaje = 'Ocurrió un error inesperado al intentar cargar el detalle de la estadística';
          this.enviarMensaje.emit({ mensaje: this.mensaje, tipo: 'E' })
        }
      )
  }

  cerrarDialogoFiltros() {
    // let tipo = this.definirTipoDasboard();
    // if(tipo!=this.tipoDash){
    //     this.tipoDash = tipo;
    this.obtenerGraficas();
    // }
  }

  //Función que devuelve el dashboard a su estado inicial
  volverGraficaInicial() {
    this.obtenerGraficas();
    this.tituloDetalle = '';
    this.esDetalle = false;
  }

  //Función para calcular la fecha y la hora
  calcularFechaHora() {
    let meses = new Array("Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre");
    let f = new Date();
    let hours = f.getHours();
    let minutes: string = '' + (f.getMinutes());
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = parseInt(minutes) < 10 ? '0' + minutes : minutes;
    let strTime = hours + ':' + minutes + ' ' + ampm;
    return f.getDate() + " de " + meses[f.getMonth()] + " de " + f.getFullYear() + " - " + strTime;
  }

  enviarSenalImpresionGrafica(indexContenedor: string) {
    this.idContenedorOculto = indexContenedor;
    setTimeout(() => {
      this.imprimirGrafica(indexContenedor)
    }, 0);
  }

  imprimirGrafica(indexContenedor: string) {
    let nombreContenedor = "#contenedor" + indexContenedor;
    html2canvas(document.querySelector(nombreContenedor)).then(canvas => {
      let img = <HTMLFormElement>document.getElementById('descargar');
      img.href = canvas.toDataURL('image/png').replace("image/png", "image/octet-stream");
      img.download = "imagen.png";
      img.click()
    });
    this.idContenedorOculto = '-1';
  }

  exportarExcel(idesta: string) {
    let myForm = <HTMLFormElement>document.getElementById('formularioDescarga');
    let url = this._servicioDatosGenerales.getReporteExcelDashboard();
    this.idesta = idesta;
    myForm.action = url;
    setTimeout(() => {
      myForm.submit();
    }, 100);
  }

  filtrarComerciosMatkerplace() {
    this.comercio = null;
    if (this.marketplace) {
      let comercios = this._servicioDatosGenerales.getIdentity().usuario.relacionadasC;
      this.comercios = [];
      for (let i = 0; i < comercios.length; i++) {
        if (comercios[i].padre == this.marketplace.id) {
          this.comercios.push(comercios[i]);
        }
      }
    } else {
      this.comercios = this._servicioDatosGenerales.getIdentity().usuario.relacionadasC;
    }
  }
}
