import { Component, OnInit, Renderer2,OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { servicioPeticiones } from '@servicios/servicioPeticiones';
import { DatosGeneralesService } from '@servicios/datosGenerales.service';
import { Router, ActivatedRoute } from '@angular/router';
import { funcionesSesion } from '@funcionesGenerales/funcionesSesion';




@Component({
  selector: 'app-registarse-contratista',
  templateUrl: './registarse-contratista.component.html',
  styleUrls: ['./registarse-contratista.component.css']
})
export class RegistarseContratistaComponent implements OnInit {
  private llamado: any = '';      //Contendrá el json con los datos para enviarse al servidor
  public mensaje: string = '';
  private codrespuesta: string = '-1';   //Mensajes que se mostarán dependiendo de lo que nos devuelva el servidor(apirest)
  public identity: any;           //Variable que contiene los datos de la sesion iniciada
  public tipoPeticion: string;
  public loading: boolean = false;
  public listaTabs: Array<any> = [];
  private codigoAcceso: string = '0';    //Codigo Inicial de acceso al abrir la pagina
  private idAcceso: number = 0;   //Id del acceso cuando se solicita llave de acceso
  public ingresoExitoso:boolean = false;
  data: any;

  constructor( private _http: HttpClient, private _renderer: Renderer2, private fb: FormBuilder, private _servicioPeticiones: servicioPeticiones, public _servicioDatosGenerales: DatosGeneralesService, private _router: Router, private _route: ActivatedRoute, private _funcionesSesion: funcionesSesion) {
    this._servicioPeticiones.data$.subscribe(data => {
      this.data = data;
      console.log(JSON.stringify(this.data));
      if(this.data.valores.valorSalida=="0"){
      setTimeout(() => {
        this._router.navigate(['/home', '']);
      }, 2000)
    }
    });
  }


  ngOnInit() {
    this.solicitarLlaveLogin();
  }

  volverInicio() {
    this._router.navigate(['/home', '']);
  }

  solicitarLlaveLogin() {
    this.loading = true;
    this.llamado = { "accion": "inicio" }
    this._servicioPeticiones.enviarObjetoBus(this.llamado)
      .subscribe(//suscribe la peticion http para estar pendiente de la respuesta, funciona como un callback
        response => { //si la peticion fue exitosa ejecuta la funcion response
          let respuesta = response[0];
          if (respuesta.valores) {
            if (respuesta.valores.resultado == 0) {//Conexion sin inconvenientes
                this.codigoAcceso = respuesta.valores.codigo_inicial_acceso;//Obtengo la llave privada
                this.idAcceso = respuesta.valores.id;
                this.iniciarSesionExterno(this.idAcceso, this.codigoAcceso);
            } else {
                this._servicioDatosGenerales.enviarMensaje('E', respuesta.valores.mensaje);
            }
        } else {
            this._servicioDatosGenerales.enviarMensaje('E', this._servicioDatosGenerales.getMensajeError());
        }
        this.loading = false;

        },
        error => {
          this.codrespuesta = '-1';//Se muestra en -1 para que no muestre campos de inicio se sesion
          this._servicioDatosGenerales.enviarMensaje('E', 'No se puede tener conexión, por favor recargue el sistema, si el error persiste contáctese con el administrador.');
          this.loading = false;
        })
  }

  iniciarSesionExterno(idacceso, codigoInicialAcceso) {
    console.log(idacceso + codigoInicialAcceso);
    console.log(this._funcionesSesion.encriptarRsa("[B@5c2ed8a2", codigoInicialAcceso));
  this.llamado = { "accion": "accesoExterno", parametros: { "id": idacceso, "usuario": "siscarConsolidado@datasoft.com.co", "codigoAutenticacion":this._funcionesSesion.encriptarRsa("[B@5c2ed8a2", codigoInicialAcceso)} };
  this._servicioPeticiones.enviarObjetoBus(this.llamado)
    .subscribe( //suscribe la peticion http para estar pendiente de la respuesta, funciona como un callback
      response => { //si la peticion fue exitosa ejecuta la funcion response
        let respuesta = response[0];
        if (respuesta.valores != null) {
            this._servicioDatosGenerales.setIdentity(respuesta.valores);//Guardamos la información del loggin en el localstorage
            this.ingresoExitoso = true;
        } else {
          this._servicioDatosGenerales.enviarMensaje('E', this._servicioDatosGenerales.getMensajeError());
        }
      },
      error => {//si el API genero un error
        this._servicioDatosGenerales.enviarMensaje('E', this._servicioDatosGenerales.getMensajeError());
      })

}
  tomarRutaFormulario(informacionMenu) {
    // for (let i = 0; i < this.listaTabs.length; i++) {
    //   if (this.listaTabs[i].id == informacionMenu.id) {
    //     this.listaTabs[i].ruta = informacionMenu.ruta;
    //     i = this.listaTabs.length;
    //   }
    // }
  }

  tomarMensajeComponente(datos: any) {
    this.mostrarMensaje(datos.tipo, datos.mensaje);
  }

  mostrarMensaje(tipo: string, mensaje) {
    this._servicioDatosGenerales.enviarMensaje(tipo, mensaje);
  }


  receiveData(data: object) {
    this.data = data;
    if(this.data){
      this._router.navigate(['/home', '']);
    }


  }

  ngOnDestroy() {
    this._servicioDatosGenerales.deleteIdentity();
    this._servicioDatosGenerales.limpiarMensajes();
  }

}
