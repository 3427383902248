import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
// import { DatosGeneralesService } from '@servicios/datosGenerales.service';

@Injectable()
export class servicioPeticiones {
  constructor(private _http: HttpClient) { }

  public URL: string = 'https://development.datasoftingenieria.com.co:9698/procesar';
  public recargaPadre: boolean = false;

  data$ = new Subject<Object>();
  idForm: any;

  //Función que enviará la información en json al api-rest
  enviarObjetoBus(objeto: any): Observable<any> {
    let json = JSON.stringify(objeto);
    let params = json;
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' }); //Pasar a formato encoding para enviar el objeto en la cabecera
    return this._http.post(this.URL, params, { headers: headers });
  }

  enviarObjetoSincrono(objeto: any) {
    const headers = { type: 'application/json' };
    const blob = new Blob([JSON.stringify(objeto)], headers);
    navigator.sendBeacon(this.URL, blob);
  }
  emitData(data: any) {
    this.data$.next(data);
  }

  setRecargaPadre(value: boolean) {
    this.recargaPadre = value;
  }

  getRecargaPadre() {
    return this.recargaPadre;
  }
}
