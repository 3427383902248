import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { servicioPeticiones } from '@servicios/servicioPeticiones';
import { DatosGeneralesService } from '@servicios/datosGenerales.service'
import { campoFormulario } from '@modelos/campo'

@Component({
  selector: 'app-formulario-multiregistro',
  templateUrl: './formulario-multiregistro.component.html',
  styleUrls: ['./formulario-multiregistro.component.css']
})
export class FormularioMultiregistroComponent implements OnInit {
    @Input() idFormulario:string;
    @Input() idRegistroPadre:string;
    @Input() referidosFormulario:any;// Llaves que se necesitan para poder enviar los referidos en el momento de realiqar la búesqueda de multiregistro
    @Input() camposPadre:any;// Llaves que se necesitan para poder enviar los referidos en el momento de realiqar la búesqueda de multiregistro
    @Output() cerrarFormulario = new EventEmitter();
    public loading:boolean=false;
    public tablaResultados:any;
    public camposReferidos:Array<campoFormulario>;

    constructor(private _servicioPeticiones: servicioPeticiones, public _servicioDatosGenerales: DatosGeneralesService) { }

    ngOnInit() {
        this.llenarValoresReferidosPadre();
        this.obtenerInformacionInicial();
    }

    llenarValoresReferidosPadre(){
        this.camposReferidos=[];
        if(this.referidosFormulario && this.referidosFormulario.length>0){
            let j:number;
            let campo:campoFormulario;
            for(let i=0; i<this.referidosFormulario.length;i++){
                j=0;
                while(j<this.camposPadre.length){
                    if(this.referidosFormulario[i]==this.camposPadre[j].nombre){
                        campo = new campoFormulario(this.referidosFormulario[i],'',this.camposPadre[j].valor);
                        j = this.camposPadre.length;
                        this.camposReferidos.push(campo);
                    }
                    j++;
                }
            }
        }        
    }

    obtenerInformacionInicial(){
        this.loading = true;
        let llamado = {"accion":"busquedaMultiregistro",parametros:{"codigoSesion":this._servicioDatosGenerales.getIdentity().codigoSesion,"formulario":this.idFormulario,"identificadorPadre":this.idRegistroPadre, "campos":this.camposReferidos}};
        this._servicioPeticiones.enviarObjetoBus(llamado)
            .subscribe( //suscribe la peticion http para estar pendiente de la respuesta, funciona como un callback
                response => { //si la peticion fue exitosa ejecuta la funcion response
                    let respuesta = response[0].valores;
                    if(respuesta.resultado==0){//Si No obtiene problemas al traer el menú
                        this.tablaResultados = respuesta.tablaResultados;
                    }else{
                        this._servicioDatosGenerales.enviarMensaje('E',respuesta['mensaje']);    
                    }
                    this.loading = false;
                },
                error => {
                    this.loading = false;
                    this._servicioDatosGenerales.enviarMensaje('E',this._servicioDatosGenerales.getMensajeError());   
                }
            )
    }

    enviarInformacionFormulario(){
        this.loading = true;
        let llamado = {"accion":"adicionarActualizarMultiregistro",parametros:{"codigoSesion":this._servicioDatosGenerales.getIdentity().codigoSesion,"formulario":this.idFormulario,"identificadorPadre":this.idRegistroPadre,"registros":this.tablaResultados.registros}};
        this._servicioPeticiones.enviarObjetoBus(llamado)
            .subscribe( //suscribe la peticion http para estar pendiente de la respuesta, funciona como un callback
                response => { //si la peticion fue exitosa ejecuta la funcion response
                    let respuesta = response[0].valores;
                    if(respuesta){
                        if(respuesta.resultado==0){//Si No obtiene problemas al traer el menú
                            this._servicioDatosGenerales.enviarMensaje('C','Registros almacenados correctamente');
                        }else{
                            this._servicioDatosGenerales.enviarMensaje('E',respuesta['mensaje']);     
                        }
                    }else{
                        this._servicioDatosGenerales.enviarMensaje('E',this._servicioDatosGenerales.getMensajeError());
                    }                    
                    this.loading = false;
                },
                error => {
                    this.loading = false;
                    this._servicioDatosGenerales.enviarMensaje('E',this._servicioDatosGenerales.getMensajeError());
                }
            )
    }

    //Cierra el formulario 
    cerrarEstructuraFormulario(){
        this.cerrarFormulario.emit();
    }

    recargarDatosTabla(idmenu:string, valor:any, posicion:string, superior:string){
        this.recargarDatosTablaHijos(idmenu, valor, posicion);
        this.recargarDatosTablaPadre(superior, valor, posicion);
    }

    recargarDatosTablaHijos(idmenu:string, valor:any, posicion:string){ 
        for(let i = 0; i<this.tablaResultados.registros.length; i++){
            if(idmenu==this.tablaResultados.registros[i].superior){
                this.tablaResultados.registros[i].datos[posicion]=valor;
                this.recargarDatosTablaHijos(this.tablaResultados.registros[i].relacion,this.tablaResultados.registros[i].datos[posicion],posicion);
            }
        }
    }

    recargarDatosTablaPadre(superior:string, valor:any, posicion:string){
        let i = 0;
        while (i<this.tablaResultados.registros.length){
            if(superior == this.tablaResultados.registros[i].relacion && (this.tablaResultados.registros[i].datos[posicion]=='0' || this.tablaResultados.registros[i].datos[posicion]=='' || this.tablaResultados.registros[i].datos[posicion]=='00000')){
                this.tablaResultados.registros[i].datos[posicion]=valor;
                this.recargarDatosTablaPadre(this.tablaResultados.registros[i].superior, valor, posicion);
                i= this.tablaResultados.registros.length;
            }
            i++;
        }
    }
}